import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import {
    DashboardOutlined,
    PlusOutlined,
    ArrowLeftOutlined,
    UnorderedListOutlined,
    ArrowRightOutlined,
    StockOutlined,
    SettingOutlined,
    ClusterOutlined,
    FileSyncOutlined,
    FileAddOutlined,
    SwapOutlined,
    FundViewOutlined,
    SearchOutlined,
    FileSearchOutlined
} from "@ant-design/icons";
import { FaTruck } from "react-icons/fa";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import { AuthContext } from "context/AuthContext";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
    MdThumbUpOffAlt,
    MdBeenhere,
    MdHdrStrong,
    MdPayment,
} from "react-icons/md";
import CentralRoute from "helpers/CentralRoute";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;
const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
    let keyList = [];
    let keyString = "";
    if (key) {
        const arr = key.split("-");
        for (let index = 0; index < arr.length; index++) {
            const elm = arr[index];
            index === 0
                ? (keyString = elm)
                : (keyString = `${keyString}-${elm}`);
            keyList.push(keyString);
        }
    }
    return keyList;
};

const SideNavContent = (props) => {
    const {
        sideNavTheme,
        routeInfo,
        hideGroupTitle,
        localization,
        onMobileNavToggle,
    } = props;
    const [user] = useContext(AuthContext);
    // la liste des tous les roles
    const roles = [
        "ADMIN_SUPER",
        "ASSISTANTE",
        "PRESIDENT",
        "RESPONSABLE ACHATS",
        "GERANT",
        "CAISSIERE",
        "RAYONNISTE",
    ];

    // les deux lignes nous permet de masquer les menus bon de commande et bon de reception
    // les roles citer dans le tableau ont accès au menus mais les roles non citer n'ont pas accès
    const rolesAutorisesGerantETCaissiereBcommande = [
        "ADMIN_SUPER",
        "PRESIDENT",
        "GERANT",
        "RESPONSABLE ACHATS",
    ];
    const menuVisibleGerantBcommande =
        rolesAutorisesGerantETCaissiereBcommande.includes(user.Role?.libelle);

    // les deux lignes nous permet de masquer les menus bon de commande et bon de reception
    // les roles citer dans le tableau ont accès au menus mais les roles non citer n'ont pas accès
    // const rolesAutorisesAssistante = ["ADMIN_SUPER", "CAISSIERE", "PRESIDENT"];
    const rolesAutorisesAssistante = ["CAISSIERE"];
    const menuVisibleAssistante = rolesAutorisesAssistante.includes(
        user.Role?.libelle
    );

    // les deux lignes nous permet de masquer les menus entrepot
    // les roles citer dans le tableau ont accès au menus mais les roles non citer n'ont pas accès
    const rolesAutorisesEntrepot = [
        "ADMIN_SUPER",
        "ASSISTANTE",
        "PRESIDENT",
        "RESPONSABLE ACHATS",
        "GERANT",
    ];
    const menuVisible = rolesAutorisesEntrepot.includes(user.Role?.libelle);

    // les deux lignes nous permet de masquer les menus entrepot
    // les roles citer dans le tableau ont accès au menus mais les roles non citer n'ont pas accès
    const rolesAutorisesFactureAvoirEtDoit = [
        "ADMIN_SUPER",
        "PRESIDENT",
        "GERANT",
    ];
    const menuVisibleFactureAvoirEtDoit =
        rolesAutorisesFactureAvoirEtDoit.includes(user.Role?.libelle);

    // les deux lignes nous permet de masquer les menus entrepot
    // les roles citer dans le tableau ont accès au menus mais les roles non citer n'ont pas accès
    const rolesAutorisesValiderBCommande = [
        "ADMIN_SUPER",
        "RESPONSABLE ACHATS",
        "GERANT"
    ];
    const menuVisibleValiderBCommande = rolesAutorisesValiderBCommande.includes(
        user.Role?.libelle
    );

    // les deux lignes nous permet de masquer les menus entrepot
    // les roles citer dans le tableau ont accès au menus mais les roles non citer n'ont pas accès
    const rolesAutorisesConfiguration = ["ADMIN_SUPER", "RESPONSABLE ACHATS","GERANT"];
    const menuVisibleConfiguration = rolesAutorisesConfiguration.includes(
        user.Role?.libelle
    );

    const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
    const closeMobileNav = () => {
        if (isMobile) {
            onMobileNavToggle(false);
        }
    };

    useEffect(() => {
    }, []);

    return (
        <div>
            {!(user.Role?.libelle === "CAISSIERE") && (
            <Menu
                theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
                mode="inline"
                style={{ height: "100%", borderRight: 0, fontSize: "16px" }} //  backgroundColor:"orange"
                defaultSelectedKeys={[routeInfo?.key]}
                defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
                className={hideGroupTitle ? "hide-group-title" : ""}
            >
            {/* tableau de bord */}
            <Menu.Item 
                key="home"
                style={{ display: menuVisibleFactureAvoirEtDoit ? "block" : "none" }}
            >
                <Icon type={DashboardOutlined} />
                <span>Tableau de bord</span>
                {`${APP_PREFIX_PATH}/home` ? (
                    <Link
                        onClick={() => closeMobileNav()}
                        to={`${APP_PREFIX_PATH}/home`}
                    />
                ) : null}
            </Menu.Item>

            {/* Separateur*/}
            <Menu.ItemGroup 
            key="option" 
            title="GESTION"
            style={{ display: menuVisibleFactureAvoirEtDoit ? "block" : "none" }}
            >

            </Menu.ItemGroup>

            {/* Gestion */}
            <SubMenu
                icon={<Icon type={MdBeenhere} />}
                key="apps-management-credentialss"
                title={<span>Gestions</span>}
                style={{ display: menuVisible ? 'block' : 'none' }}
            >
                <Menu.Item key="apps-article-listt">
                    {<Icon type={UnorderedListOutlined} />}
                    <span>Gestion des Produits</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/produit/liste-produit`}
                        />
                    }
                </Menu.Item>
            </SubMenu>

            {/* Achat fournisseurs */}
            <SubMenu
                icon={<Icon type={MdHdrStrong} />}
                style={{
                    display: menuVisibleGerantBcommande ? "block" : "none",
                }}
                key="apps-CommandeGestPrincipal"
                title="Achat Fournisseurs"
            >
                <Menu.Item key="apps-mesBonsFournisseurs-list">
                    {<Icon type={ArrowLeftOutlined} />}
                    <span>Bon de commande</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={CentralRoute.getRouteListeBonCommande()}
                        />
                    }
                </Menu.Item>

                {/* Bon de réception */}
                <Menu.Item key="apps-mesBonsReceptions-list">
                    {<Icon type={ArrowRightOutlined} />}
                    <span>Bon de reception</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/bon-reception/liste`}
                        />
                    }
                </Menu.Item>
            </SubMenu>

            {/* Reglement */}
            <SubMenu
                icon={<Icon type={MdPayment} />}
                style={{
                    display: menuVisibleFactureAvoirEtDoit ? "block" : "none",
                }}
                key="apps-reglement"
                title="Reglement"
            >
                <Menu.Item key="apps-mvt-in-add">
                    {<Icon type={FileAddOutlined} />}
                    <span>Facture Avoir</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={CentralRoute.getRouteListeFactureAvoir()}
                        />
                    }
                </Menu.Item>

                <Menu.Item key="apps-mesMvt-list">
                    {<Icon type={FileSyncOutlined} />}
                    <span>Facture Doits</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/facture-doit/liste`}
                        />
                    }
                </Menu.Item>
            </SubMenu>

           {/* Caisse */}
            <SubMenu
                icon={<Icon type={ClusterOutlined} />}
                style={{ display: menuVisibleAssistante ? "block" : "none" }}
                key="apps-caisse-ventes"
                title="Caisse"
            >
                <Menu.Item key="caisse-ecran">
                    {<Icon type={PlusOutlined} />}
                    <span>Ecran</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            // to={`${APP_PREFIX_PATH}/caisses/caisse`}
                            to={CentralRoute.getRouteCaisse()}

                        />
                    }
                </Menu.Item>

            </SubMenu>

            {/* Stock */}
            <SubMenu
                icon={<Icon type={StockOutlined} />}
                key="apps-stock"
                style={{ display: menuVisible ? "block" : "none" }}

                title="Stock"
            >
                <Menu.Item key="apps-direction-inventaire">
                    {<Icon type={StockOutlined} />}
                    <span>Inventaire</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/stock-entrepot/liste-inventaire`}
                        />
                    }
                </Menu.Item>

                <Menu.Item key="apps-direction-stock">
                    {<Icon type={StockOutlined} />}
                    <span>Entrepôt</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/stock-entrepot/liste-entrepot`}
                        />
                    }
                </Menu.Item>

                <Menu.Item key="apps-showroom-stock">
                    {<Icon type={StockOutlined} />}
                    <span>Rayon</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={CentralRoute.getRouteListeStockRayon()}
                        />
                    }
                </Menu.Item>

                {/* <SubMenu
                    icon={<Icon type={FaTruck} />}
                    key="apps-approvisionnement"
                    title="Approvisionnements"
                >
                    <SubMenu
                        icon={<Icon type={FaTruck} />}
                        key="apps-appro-request"
                        title="Demande d'approvisionnement"
                    >
                        <Menu.Item key="apps-appro.-add">
                            {<Icon type={PlusOutlined} />}
                            <span>Nouveau</span>
                            {
                                <Link
                                    onClick={() => closeMobileNav()}
                                    to={`${APP_PREFIX_PATH}/approvisionnements/ajout`}
                                />
                            }
                        </Menu.Item>

                        <Menu.Item key="apps-myRequestsAppro-list">
                            {<Icon type={UnorderedListOutlined} />}
                            <span>Liste</span>
                            {
                                <Link
                                    onClick={() => closeMobileNav()}
                                    to={`${APP_PREFIX_PATH}/approvisionnements/mes-demandes`}
                                />
                            }
                        </Menu.Item>

                        <Menu.Item key="apps-bonCmdApproEnCours-list">
                            {<Icon type={UnorderedListOutlined} />}
                            <span>En Attente de validation</span>
                            {
                                <Link
                                    onClick={() => closeMobileNav()}
                                    to={`${APP_PREFIX_PATH}/approvisionnements/bons-cmd-en-cours`}
                                />
                            }
                        </Menu.Item>
                    </SubMenu>
                </SubMenu> */}
            </SubMenu>

            {/* validations et confirmations */}
            <SubMenu
                icon={<Icon type={MdThumbUpOffAlt} />}
                key="apps-all-validation"
                title="Validations"
                style={{
                    display: menuVisibleValiderBCommande ? "block" : "none",
                }}
            >
                <Menu.Item key="apps-mesBonCmds-list-DG">
                    {<Icon type={ArrowLeftOutlined} />}
                    <span>Bon de commandes</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/bon-commande/validation`}
                        />
                    }
                </Menu.Item>

                
            </SubMenu>

            {/* Caisse infos */}
            <SubMenu
                icon={<Icon type={FundViewOutlined} />}
                key="apps-all-historiques"
                title="Caisse Infos"
                style={{
                    display: menuVisibleValiderBCommande ? "block" : "none",
                }}
            >
                <Menu.Item key="prelevement">
                    {<Icon type={SwapOutlined} />}
                    <span>Prélèvement</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={CentralRoute.getRouteListePrelevement()}
                        />
                    }
                </Menu.Item>

                <Menu.Item key="caissiere-liste"> 
                    {<Icon type={FileSyncOutlined} />}
                    <span>C. Clôturées</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/caissieres/liste-connecter`}
                        />
                    }
                </Menu.Item>

                <Menu.Item key="connexion-liste">
                    {<Icon type={PlusOutlined} />}
                    <span>Historiques Connexions Cassières</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/caissieres/liste-connexion-caissiere`}
                        />
                    }
                </Menu.Item>

                <Menu.Item key="annuler-vente">
                    {<Icon type={PlusOutlined} />}
                    <span>Ventes Courantes</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/vente/liste-vente-journee`}
                        />
                    }
                </Menu.Item>

                {/* <Menu.Item key="apps-mesRayon">
                    {<Icon type={UnorderedListOutlined} />}
                    <span>Liste de Toutes les Ventes</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/vente/liste-de-toutes-les-ventes`}
                        />
                    }
                </Menu.Item> */}

                <Menu.Item key="apps-mesStock">
                    {<Icon type={UnorderedListOutlined} />}
                    <span>Ventes Annulées</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/vente/liste-vente-annuler`}
                        />
                    }
                </Menu.Item>

                <Menu.Item key="search-vt"> 
                    {<Icon type={FileSearchOutlined} />}
                    <span>Recherche</span>
                    {
                        <Link
                            onClick={() => closeMobileNav()}
                            to={`${APP_PREFIX_PATH}/apps/vente/globale-recherche`}
                        />
                    }
                </Menu.Item>
            </SubMenu>

            {/* Configuration */}
            <Menu.Item
                style={{ display: menuVisibleConfiguration ? "block" : "none" }}
                key="configs-configuration"
            >
                {" "}
                {<Icon type={SettingOutlined} />}
                <span>Configuration</span>
                {
                    <Link
                        onClick={() => closeMobileNav()}
                        to={`${APP_PREFIX_PATH}/configs/configuration/generale`}
                    />
                }
            </Menu.Item>
        </Menu>
         )}
        </div>
       
    );
};

const TopNavContent = (props) => {
    const { topNavColor, localization } = props;
    return (
        <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
            {navigationConfig.map((menu) =>
                menu.submenu.length > 0 ? (
                    <SubMenu
                        key={menu.key}
                        popupClassName="top-nav-menu"
                        title={
                            <span>
                                {menu.icon ? <Icon type={menu?.icon} /> : null}
                                <span>
                                    {setLocale(localization, menu.title)}
                                </span>
                            </span>
                        }
                    >
                        {menu.submenu.map((subMenuFirst) =>
                            subMenuFirst.submenu.length > 0 ? (
                                <SubMenu
                                    key={subMenuFirst.key}
                                    popupClassName="top-nav-menu"
                                    icon={
                                        subMenuFirst.icon ? (
                                            <Icon type={subMenuFirst?.icon} />
                                        ) : null
                                    }
                                    title={setLocale(
                                        localization,
                                        subMenuFirst.title
                                    )}
                                >
                                    {subMenuFirst.submenu.map(
                                        (subMenuSecond) => (
                                            <Menu.Item key={subMenuSecond.key}>
                                                <span>
                                                    {setLocale(
                                                        localization,
                                                        subMenuSecond.title
                                                    )}
                                                </span>
                                                <Link to={subMenuSecond.path} />
                                            </Menu.Item>
                                        )
                                    )}
                                </SubMenu>
                            ) : (
                                <Menu.Item key={subMenuFirst.key}>
                                    {subMenuFirst.icon ? (
                                        <Icon type={subMenuFirst?.icon} />
                                    ) : null}
                                    <span>
                                        {setLocale(
                                            localization,
                                            subMenuFirst.title
                                        )}
                                    </span>
                                    <Link to={subMenuFirst.path} />
                                </Menu.Item>
                            )
                        )}
                    </SubMenu>
                ) : (
                    <Menu.Item key={menu.key}>
                        {menu.icon ? <Icon type={menu?.icon} /> : null}
                        <span>{setLocale(localization, menu?.title)}</span>
                        {menu.path ? <Link to={menu.path} /> : null}
                    </Menu.Item>
                )
            )}
        </Menu>
    );
};

const MenuContent = (props) => {
    return props.type === NAV_TYPE_SIDE ? (
        <SideNavContent {...props} />
    ) : (
        <TopNavContent {...props} />
    );
};

const mapStateToProps = ({ theme }) => {
    const { sideNavTheme, topNavColor } = theme;
    // return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
