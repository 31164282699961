import { APP_PREFIX_PATH } from "configs/AppConfig";

class CentralRoute {

	static getRouteHome() {
		return `${APP_PREFIX_PATH}/dashboards/default`;
	}

	// Configuration - famille liste
	static getRouteShowListeProduit() {
		return `${APP_PREFIX_PATH}/configs/configuration/famille-liste`;
	}

	// Configuration - banque liste
	static getRouteListeBanque() {
		return `${APP_PREFIX_PATH}/configs/configuration/banque/liste`;
	}

	// Reglement facture de doit
	// static getRouteReglementFactureDoit(id) {
	// 	return `${APP_PREFIX_PATH}/apps/reglement-facture-doit/reglement/${id}`;
	// }

	// Configuration - mode de paiement liste
	static getRouteListeModePaiement() {
		return `${APP_PREFIX_PATH}/configs/configuration/mode-paiement/liste`;
	}

	// Configuration - mode de paiement liste
	static getRouteListePrelevement() {
		return `${APP_PREFIX_PATH}/apps/prelevement-caisse/liste-prelevement`;
	}

	// Configuration - mode de paiement liste
	static getRouteCaisse() {
		return `${APP_PREFIX_PATH}/caisses/caisse`;
	}

	// Bon de commande
	static getRouteListeBonCommande() {
		return `${APP_PREFIX_PATH}/apps/bon-commande/liste`;
	}
	static getRouteListeValidationBonCommande() {
		return `${APP_PREFIX_PATH}/apps/bon-commande/validation`;
	}

	// Bon de reception
	static getRouteShowBonReception(id) {
		return `${APP_PREFIX_PATH}/apps/bon-reception/afficher/${id}`;
	}

	static getRouteValiderBonReception(id) {
		return `${APP_PREFIX_PATH}/apps/bon-reception/validation/${id}`;
	}

	static getRouteListeBonReception() {
		return `${APP_PREFIX_PATH}/apps/bon-reception/liste`;
	}

	static getRouteListeStockRayon() {
		return `${APP_PREFIX_PATH}/apps/stock-entrepot/liste-rayon`;
	}

	static getRouteSuspendBonReception(id) {
		return `${APP_PREFIX_PATH}/apps/bon-reception/reglement/${id}`;
	}

	static getRouteShowItemReglesBonReception(id) {
		return `${APP_PREFIX_PATH}/apps/bon-reception/visualiser/reglement/${id}`;
	}

	// Produit / Article
	static getRouteListeArticle() {
		return `${APP_PREFIX_PATH}/apps/produit/liste-produit`;
	}

	static getRouteAddNewArticle() {
		return `${APP_PREFIX_PATH}/apps/produit/ajouter-produit`;
	}

	static getRouteUpdateArticle(id) {
		return `${APP_PREFIX_PATH}/apps/produit/modifier/${id}`;
	}

	// Stock history
	static getRouteHistoryByEntrepot(id) {
		return `${APP_PREFIX_PATH}/apps/stock-entrepot/history/${id}`;
	}

	static getRouteDetailVenteById(id) {
		return `${APP_PREFIX_PATH}/apps/vente/detail-de-vente/${id}`;
	}

	// Detail Vente
	static getRouteListeStock() {
		return `${APP_PREFIX_PATH}/apps/stock-entrepot/liste-entrepot`;
	}

	// Facture avoire
	static getRouteListeFactureAvoir() {
		return `${APP_PREFIX_PATH}/apps/facture-avoir/liste`;
	}

	// Caisse
	static getRouteListeCaisse() {
		return `${APP_PREFIX_PATH}/configs/configuration/caisse/liste`;
	}

	static getRouteModifierCaisse(id) {
		return `${APP_PREFIX_PATH}/configs/configuration/caisse/modifier/${id}`;
	}

	static getRouteCreateCaisse() {
		return `${APP_PREFIX_PATH}/configs/configuration/caisse/nouvelle`;
	}

}

export default CentralRoute;
