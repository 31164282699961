import { 
  DashboardOutlined, 
  AppstoreOutlined,
  FileTextOutlined,
  DotChartOutlined,
  MailOutlined,
  SettingOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { FaTruck } from 'react-icons/fa';

const extraNavTree = [
  {
    key: 'extra',
    path: `${APP_PREFIX_PATH}/pages`,
    title: 'sidenav.pages',
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'extra-pages',
        path: `${APP_PREFIX_PATH}/pages`,
        title: 'sidenav.pages',
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: 'extra-pages-profile',
            path: `${APP_PREFIX_PATH}/pages/profile`,
            title: 'sidenav.pages.profile',
            icon: '',
            breadcrumb: false,
            submenu: []
          },
          {
            key: 'extra-pages-list',
            path: `${APP_PREFIX_PATH}/pages/user-list`,
            title: 'sidenav.pages.userlist',
            icon: '',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'extra-pages-invoice',
            path: `${APP_PREFIX_PATH}/pages/invoice`,
            title: 'sidenav.pages.invoice',
            icon: '',
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'extra-pages-setting',
            path: `${APP_PREFIX_PATH}/pages/setting`,
            title: 'sidenav.pages.setting',
            icon: '',
            breadcrumb: true,
            submenu: []
          }
        ]
      },

    ]
  }
]

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-default',
      path: `${APP_PREFIX_PATH}/dashboards/default`,
      title: 'sidenav.dashboard.default',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-analytic',
      path: `${APP_PREFIX_PATH}/dashboards/analytic`,
      title: 'sidenav.dashboard.analytic',
      icon: DotChartOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'dashboards-sales',
      path: `${APP_PREFIX_PATH}/dashboards/sales`,
      title: 'sidenav.dashboard.sales',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const appsNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'sidenav.apps',
  icon: AppstoreOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'apps-mail',
      path: `${APP_PREFIX_PATH}/apps/mail/inbox`,
      title: 'sidenav.apps.mail',
      icon: MailOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'apps-fournisseur',
      path: `${APP_PREFIX_PATH}/apps/fournisseur`,
      title: 'Fournisseur',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'apps-Fournisseur-list',
          path: `${APP_PREFIX_PATH}/apps/fournisseur/liste`,
          title: 'Liste',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'apps-Fournisseur-nouveau',
          path: `${APP_PREFIX_PATH}/apps/fournisseur/nouveau`,
          title: 'Nouveau',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'apps-produit',
      path: `${APP_PREFIX_PATH}/apps/produit`,
      title: 'Produit',
      icon: ShoppingCartOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'apps-produit-productList',
          path: `${APP_PREFIX_PATH}/apps/produit/product-list`,
          title: 'productList',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'apps-produit-addProduct',
          path: `${APP_PREFIX_PATH}/apps/produit/add-product`,
          title: 'addProduct',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-produit-editProduct',
          path: `${APP_PREFIX_PATH}/apps/produit/edit-product/12`,
          title: 'editProduct',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'apps-produit-orders',
          path: `${APP_PREFIX_PATH}/apps/produit/orders`,
          title: 'orders',
          icon: '',
          breadcrumb: false,
          submenu: []
        }
      ]
    }
  ]
}]



const docsNavTree = [{
  key: 'docs',
  path: `${APP_PREFIX_PATH}/docs`,
  title: 'sidenav.docs',
  icon: BookOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'docs-documentation',
      path: `${APP_PREFIX_PATH}/docs/documentation`,
      title: 'sidenav.docs.documentation',
      icon: FileUnknownOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'configs-configuration',
      path: `${APP_PREFIX_PATH}/configs/configuration/generale`,
      title: 'Configuration',
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: 'docs-changelog',
    //   path: `${APP_PREFIX_PATH}/docs/documentation/changelog`,
    //   title: 'sidenav.docs.changelog',
    //   icon: ProfileOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // }
  ]
}]

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...extraNavTree,
  ...docsNavTree
]

export default navigationConfig;
