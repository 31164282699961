import userCalls from "api/users/userCalls";
import React, { useEffect, useState } from "react";

export const AuthContext = React.createContext();

export const AuthProvider = (props) => {
    const [user, setUser] = useState({});

    const loadCurrentUser = async () => {
        const userLocal = JSON.parse(localStorage.getItem("auth_user"));
        const getToken = localStorage.getItem("auth_token");
        let token = { token: getToken };
        if (userLocal) {
            try {
                let res = await userCalls.getSingleUser(userLocal.id);
                setUser(Object.assign(res.data.user, token));
            } catch (error) {
                setUser(null);
            }
        } else {
            setUser(null);
        }
    };

    useEffect(() => {
       
        (async () => {
            try {
                const response = JSON.parse(localStorage.getItem("auth_user"));
                const getToken = localStorage.getItem("auth_token");
                let token = { token: getToken };
                if (response) {
                    userCalls
                        .getSingleUser(response.id)
                        .then((res) => {
                            setUser(Object.assign(res.data.user, token));
                        })
                        .catch((err) => console.log(err.response));
                } else {
                    setUser(null);
                }
            } catch (error) {
                setUser(null);
            }
        })();
    }, []);

    return (
        <AuthContext.Provider value={[user, setUser]}>
            {props.children}
        </AuthContext.Provider>
    );
};
export default AuthProvider;
