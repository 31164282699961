import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/errors`} component={lazy(() => import(`./errors`))} />
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />

        {/* vers produit, fournisseur */}
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/configs`} component={lazy(() => import(`./configurations`))} />

        <Route path={`${APP_PREFIX_PATH}/articles`} component={lazy(() => import(`./articles`))} />
        <Route path={`${APP_PREFIX_PATH}/entrees`} component={lazy(() => import(`./entrees`))} />
        <Route path={`${APP_PREFIX_PATH}/caisses`} component={lazy(() => import(`./caisses`))} />
        <Route path={`${APP_PREFIX_PATH}/clients`} component={lazy(() => import(`./clients`))} /> 
        <Route path={`${APP_PREFIX_PATH}/stocks`} component={lazy(() => import(`./stocks`))} /> 
        <Route path={`${APP_PREFIX_PATH}/approvisionnements`} component={lazy(() => import(`./approvisionnement showrooms`))} />

        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} /> 
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
