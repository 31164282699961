import axios from 'axios';

const userCalls = {}

const token = localStorage.getItem('auth_token')
const config = {
    headers: { Authorization: `Bearer ${token}` }
};

// get all users list
userCalls.listUsers = async () =>{
    return await axios.get(`${process.env.REACT_APP_URL}/api/user/liste`, config) 
}

// delete a user
userCalls.deleteUser = async (userId) =>{
    return await axios.delete(`${process.env.REACT_APP_URL}/api/user/delete/${userId}`, config) 

}

userCalls.deleteDemandeConnexion = async (userId) =>{
    return await axios.delete(`${process.env.REACT_APP_URL}/api/user/delete/demande/connexion/${userId}`, config) 

}

// get a single user
userCalls.getSingleUser = async (userId) =>{
    return await axios.get(`${process.env.REACT_APP_URL}/api/user/${userId}`, config) 
}

// get a single user
userCalls.getSingleLogHistory = async (id) =>{
    return await axios.get(`${process.env.REACT_APP_URL}/api/user/getOneLogHistory/${id}`, config) 
}

// get a Showroom user
userCalls.getShowroomUser = async (userId) =>{
    return await axios.get(`${process.env.REACT_APP_URL}/api/user/showroom/${userId}`, config) 
}

// get roles list
userCalls.getAllRoles = async () =>{
    return await axios.get(`${process.env.REACT_APP_URL}/api/role/user/show/all`, config) 
}

// create user
userCalls.handleNewUser = async (userData) =>{
    return await axios.post(`${process.env.REACT_APP_URL}/api/user/register`, userData, config) 
}

// update user
userCalls.handleUpdateUser = async (userId, userData) =>{
    return await axios.put(`${process.env.REACT_APP_URL}/api/user/edit/${userId}`, userData, config) 
}

// edit password
userCalls.updatePassword = async (userId, userData) =>{
    return await axios.put(`${process.env.REACT_APP_URL}/api/user/password/edit/${userId}`, userData, config) 
}

// liste authorization caissier
userCalls.listeAuthorizationCaissier = async () => {
    return await axios.get(`${process.env.REACT_APP_URL}/api/authorization/today-liste`, config) 
}

// authorizer une connexion
userCalls.AuthoriserConnexionCaissier = async (dmdId) => {
    return await axios.put(`${process.env.REACT_APP_URL}/api/authorization/confirm/auth/${dmdId}`, null, config) 
}

// Pause d'une caissière
userCalls.pauseCaissiere = async (userId) =>{
    return await axios.get(`${process.env.REACT_APP_URL}/api/user/pause/caissiere/${userId}`, config) 
}

// clôture d'une caissière
userCalls.clotureCaisse = async (userId, userData) => {
    return await axios.put(`${process.env.REACT_APP_URL}/api/user/cloture/caissiere/${userId}`, userData, config) 
}

export default userCalls;